
import WrapperFormQuestion from '@/app/Survey/components/WrapperFormQuestion.vue';
import QuestionMixin from '@/app/Survey/FormComponents/QuestionMixin';
import { BButton } from 'bootstrap-vue';
import { Component, Mixins } from 'vue-property-decorator';

@Component({ components: { WrapperFormQuestion, BButton } })
export default class EmojiComponent extends Mixins(QuestionMixin) {
    public emojiIcons: { [key: string]: number[] } = {
        NPS: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        CSAT: [1, 2, 3, 4, 5],
        CSAT10: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        CES: [0, 3, 4, 7, 8, 9, 10],
        CES2: [0, 3, 4, 7, 8, 9, 10],
        NSS: [0, 4, 6, 8, 10],
    };

    public get answer(): number {
        if (this.currentComponent.answer) {
            return this.singleAnswer.answer;
        }
        return null;
    }

    public get emojiMethodology(): string {
        return this.componentSettings.methodology === 'CSAT' ? 'csat' : 'nps';
    }

    public setEmojiAnswer(methodologyAnswers: number[], index: number): void {
        if (methodologyAnswers.length === 11) {
            this.setAnswer({ answer: index });
        } else {
            this.setAnswer({ answer: index + 1 });
        }
    }

    public isEmojiActive(emojiIcon: number): boolean {
        if (this.emojiIcons[this.componentSettings.methodology].length === 11) {
            return this.answer === this.emojiIcons[this.componentSettings.methodology].indexOf(emojiIcon);
        } else {
            return this.answer === this.emojiIcons[this.componentSettings.methodology].indexOf(emojiIcon) + 1;
        }
    }
}
